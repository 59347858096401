




























import { Component, Vue } from 'vue-property-decorator';
import TUSTLogo from '../assets/about/tust.png';
import ALiLogo from '../assets/about/ali.png';
import XCFLogo from '../assets/about/xcf.png';
import BDLogo from '../assets/about/bytedance.png';

@Component({})
export default class About extends Vue {
  private infos = [
    {
      title: 'education.title',
      items: [
        {
          logo: TUSTLogo,
          title: 'education.tust_name',
          date: '2015.9-2019.6',
          desc: 'education.tust_desc',
        },
      ],
    },
    {
      title: 'work.title',
      items: [
        {
          logo: BDLogo,
          title: 'work.bytedance_name',
          date: '2021.8-',
          tag: 'work.working_tag',
          desc: 'work.bytedance_desc',
        },
        {
          logo: ALiLogo,
          title: 'work.ali_name',
          date: '2019.7-2021.8',
          desc: 'work.ali_desc',
        },
        {
          logo: XCFLogo,
          title: 'work.xcf_name',
          date: '2018.10-2019.3',
          desc: 'work.xcf_desc',
        },
        {
          logo: ALiLogo,
          title: 'work.ali_intern_name',
          date: '2018.7-2018.8',
          desc: 'work.ali_intern_desc',
        },
      ],
    },
    {
      title: 'paper.title',
      items: [
        {
          title: 'paper.cpci_name',
          tag: 'paper.cpci_tag',
          desc: 'paper.cpci_desc',
        },
        {
          title: 'paper.chn_name',
          tag: 'paper.chn_tag',
          desc: 'paper.chn_desc',
        },
      ],
    },
    {
      title: 'projects.title',
      items: [
        {
          title: 'projects.github',
          desc: 'projects.private',
        },
      ],
    },
  ];
}
